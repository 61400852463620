import React from "react"

import { HamburgerStyled, HamburgerLineStyled } from "./styles"

interface IProps {
  clickHandler: () => void
  isOpened?: boolean
}

const Burger: React.FC<IProps> = ({ clickHandler, isOpened }) => {
  return (
    <HamburgerStyled
      aria-label="Open menu"
      type="button"
      onClick={clickHandler}
      {...{ isOpened }}
    >
      <HamburgerLineStyled />
    </HamburgerStyled>
  )
}

export default Burger
