import styled, { css } from "styled-components"
import { Link } from "gatsby"

import { transition } from "./../../common/mixins"
import { media, customMedia } from "../../common/MediaQueries"

const MainNavListStyled = styled.ul`
  display: flex;
  flex-direction: column;
`

const MainNavItemStyled = styled.li`
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @media ${media.tablet} {
    &:not(:last-child) {
      margin-bottom: 36px;
    }
  }
  @media (orientation: landscape) and (max-width: 768px) {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`

const MainNavLinkStyled = styled(Link)`
  ${transition()};
  display: block;
  font-family: ${({ theme }) => theme.fontFamilies.publico};
  color: ${({ theme }) => theme.colors.white};

  font-weight: ${({ theme }) => theme.fontWeight.black};
  font-size: 36px;
  line-height: 1.1;
  letter-spacing: -0.6px;

  &:hover {
    color: ${({ theme }) => theme.colors.brand};
  }

  &.active {
    color: ${({ theme }) => theme.colors.brand};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }

  @media ${media.mobile} {
    font-size: 36px;
    line-height: 1.1;
    letter-spacing: -0.6px;
  }

  @media ${media.tablet} {
    font-size: 48px;
    line-height: 1.25;
    letter-spacing: -0.8px;
  }
`

const MainNavStyled = styled.div`
  display: flex;
  align-items: center;
`

export {
  MainNavStyled,
  MainNavListStyled,
  MainNavItemStyled,
  MainNavLinkStyled,
}
