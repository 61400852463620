import React from "react"

import {
  InTouchBlockStyled,
  InTouchBlockHeadStyled,
  InTouchBlockLinkStyled,
} from "./styles"
import { Heading3 } from "./../Typography"
import LinkItem from "./../LinkItem"
import theme from "../../themes/theme"

interface IProps {
  heading: React.ReactNode
  isAlt?: boolean
  isReversed?: boolean
}

const InTouchBlock: React.FC<IProps> = ({ heading, isAlt, isReversed }) => (
  <InTouchBlockStyled>
    <InTouchBlockHeadStyled>
      <Heading3 as="h2" fontWeight={theme.fontWeight.black}>
        {heading}
      </Heading3>
    </InTouchBlockHeadStyled>
    <InTouchBlockLinkStyled>
      <LinkItem
        as="a"
        {...{ isAlt, isReversed }}
        isBig
        href="mailto:justin.boucher@luminous.co.uk"
        text="justin.boucher@luminous.co.uk"
        aria-label="Email"
        rel="noopener"
      />
    </InTouchBlockLinkStyled>
  </InTouchBlockStyled>
)

export default InTouchBlock
