import theme from "../themes/theme"

const [phone, tablet, desktop] = theme.breakpoints

export const media = {
  phone: `(min-width: ${phone})`,
  tablet: `(min-width: ${tablet})`,
  desktop: `(min-width: ${desktop})`,
  desktopXl: `(min-width: 1400px)`,
}

export const customMedia = {
  inner: `(min-width: ${theme.sizes.inner})`,
  maxPhoneSmall: `(max-width: 374px)`,
  maxPhone: `(max-width: ${phone})`,
  maxTablet: `(max-width: ${tablet})`,
}
