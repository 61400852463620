import React from "react"

import {
  SocialMediaListStyled,
  SocialMediaItemStyled,
  SocialMediaLinkStyled,
  SocialMediaStyled,
} from "./styles"

import LinkItem from "./../LinkItem"

interface ISocialMediaItem {
  name: string
  href: string
  target?: boolean
}

interface IProps {
  items: ISocialMediaItem[]
}

const SocialMedia: React.FC<IProps> = ({ items }) => (
  <SocialMediaStyled>
    <SocialMediaListStyled>
      {items.map(({ name, href, target }) => (
        <SocialMediaItemStyled key={name}>
          <SocialMediaLinkStyled>
            <LinkItem
              as="a"
              href={href}
              text={name}
              aria-label={name}
              rel="noopener"
              {...{ target }}
              isAlt
            />
          </SocialMediaLinkStyled>
        </SocialMediaItemStyled>
      ))}
    </SocialMediaListStyled>
  </SocialMediaStyled>
)

export default SocialMedia
