import styled, { css } from "styled-components"
import { space, maxWidth } from "styled-system"

import { media } from "./../../common/MediaQueries"
import { transition, scrollBar } from "./../../common/mixins"
import { Heading2 } from "./../Typography"
import { InnerContainer } from "./../InnerContainer"

const HeaderStyled = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: calc(100 * var(--vh));
  border-right: 2px solid ${({ theme }) => theme.colors.white};
  padding: 24px 0;

  @media ${media.tablet} {
    height: 100vh;
    min-height: auto;
  }
`

const HeaderTopStyled = styled.div`
  display: flex;
  justify-content: center;
`

const HeaderNavigationWrapperStyled = styled.div`
  display: flex;
  align-items: center;
`

const HeaderNavigationStyled = styled.div`
  ${transition()};
  display: flex;
  z-index: 2;

  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: -100vw;
  width: 100%;

  height: 100%;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  background-color: ${({ theme }) => theme.colors.black};
  transform: translateX(0);

  padding-left: ${({ theme }) => theme.viewportSidebar.mobile};

  @media ${media.phone} {
    padding-left: ${({ theme }) => theme.viewportSidebar.tablet};
  }

  @media ${media.tablet} {
    padding-left: ${({ theme }) => theme.viewportSidebar.desktop};
  }

  ${({ isOpened }) =>
    isOpened &&
    css`
      transform: translateX(100vw);
      visibility: visible;
      opacity: 1;
    `}
`

const HeaderNavigationWrapStyled = styled.div`
  ${scrollBar};
  display: block;
  max-height: calc(100 * var(--vh) - 100px);
  width: 100%;
  padding-right: 6px;
  overflow: auto;

  @media ${media.tablet} {
    max-width: 1100px;
    padding-right: 15px;
  }
`

const HeaderNavigationBlockItemStyled = styled.div`
  ${space};
  ${maxWidth};
  flex: 1;
`

const HeaderNavigationFooterStyled = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
`

const HeaderNavigationMenuStyled = styled.div`
  margin-bottom: 66px;
  @media ${media.phone} {
    margin-bottom: 54px;
  }
  @media ${media.tablet} {
    margin-bottom: 0;
  }
`

const HeaderLogoStyled = styled.div`
  margin-bottom: 106px;
  z-index: 2;
`

const HeaderNavigationBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &:first-child {
    margin-bottom: 42px;
  }
  &:nth-child(2) {
    margin-bottom: 80px;
  }

  @media ${media.phone} {
    &:first-child {
      margin-bottom: 80px;
    }
    &:nth-child(2) {
      margin-bottom: 60px;
    }
  }

  @media ${media.tablet} {
    flex-direction: row;
    &:first-child {
      margin-bottom: 80px;
    }
    &:nth-child(2) {
      margin-bottom: 42px;
    }
  }

  ${({ isReversedMobile }) =>
    isReversedMobile &&
    css`
      ${HeaderNavigationBlockItemStyled} {
        &:first-child {
          order: 2;
        }
        &:last-child {
          order: 1;
        }
        @media ${media.tablet} {
          &:first-child {
            order: 1;
          }
          &:last-child {
            order: 2;
          }
        }
      }
    `}
`

const HeaderNavigationFormStyled = styled.div``

const HeaderNavigationNewsletterStyled = styled.div`
  margin-bottom: 80px;

  @media ${media.tablet} {
    margin-bottom: 0;
  }
`

const HeaderNavigationBlockHeadStyled = styled(Heading2)`
  font-weight: ${({ theme }) => theme.fontWeight.black};
  margin-bottom: 14px;
`

const HeaderInnerContainerStyled = styled(InnerContainer)`
  padding-top: 36px;
  padding-right: 4px;
  @media ${media.phone} {
    padding-top: 54px;
  }
`

export {
  HeaderStyled,
  HeaderTopStyled,
  HeaderNavigationWrapperStyled,
  HeaderNavigationStyled,
  HeaderNavigationMenuStyled,
  HeaderLogoStyled,
  HeaderNavigationWrapStyled,
  HeaderNavigationBlockStyled,
  HeaderNavigationBlockItemStyled,
  HeaderNavigationBlockHeadStyled,
  HeaderNavigationFooterStyled,
  HeaderNavigationFormStyled,
  HeaderNavigationNewsletterStyled,
  HeaderInnerContainerStyled,
}
