import React from "react"

import { BorderLinkStyled, BorderLinkSpanStyled } from "./styles"

interface IProps {
  rel?: string
  href: string
  text: string
  target?: boolean
}

const BorderLink: React.FC<IProps> = ({ href, text, rel, target }) => {
  return (
    <BorderLinkStyled {...{ rel, target }} to={href}>
      <BorderLinkSpanStyled>{text}</BorderLinkSpanStyled>
    </BorderLinkStyled>
  )
}

export default BorderLink
