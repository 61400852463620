import styled from "styled-components"

const InTouchBlockStyled = styled.div`
  width: 100%;
`

const InTouchBlockHeadStyled = styled.div`
  margin-bottom: 10px;
`

const InTouchBlockLinkStyled = styled.div``

export { InTouchBlockStyled, InTouchBlockHeadStyled, InTouchBlockLinkStyled }
