import "../fonts/fonts.css"

export default {
  colors: {
    main: "#fff",
    black: "#000",
    white: "#fff",
    brand: "#F5FF00",
    helio: "#9A66FE",
    blue: "#3983DC",
    egg: "#20B5E3",
    green: "#00C4C4",
    red: "#b90e0a",
    grey: "#F7F9FC",
    grey2: "#BCBCBC",
    grey3: "#ebebeb",
  },
  gradients: {
    main: "linear-gradient(137deg, #012147 0%, #38b1eb 100%)",
  },
  breakpoints: ["767px", "1024px"],
  sizes: {
    inner: "1354px",
    innerSm: "1084px",
    innerM: "1232px",
    offset: "25px",
  },
  fontFamilies: {
    arsMasquette: `'ARS Maquette Pro', sans-serif;`,
    publico: `'Publico', sans-serif;`,
  },
  // Base Size: 16px
  textStyles: {
    heading1: {
      fontSize: ["40px", "60px", "60px"],
      lineHeight: ["1", "1"],
      fontFamily: "Publico",
      letterSpacing: "-1px",
    },
    heading2: {
      fontSize: "20px",
      lineHeight: "1.5",
      fontFamily: "Publico",
      letterSpacing: "-0.3px",
    },
    heading3: {
      fontSize: ["28px", "28px", "36px"],
      lineHeight: ["1.35", "1.3"],
      fontFamily: "Publico",
      letterSpacing: ["-0.2px", "0"],
    },
    heading4: {
      fontSize: ["24px", "30px", "30px"],
      lineHeight: ["1.25", "1.3", "1.3"],
      fontFamily: "Publico",
      letterSpacing: ["-0.4px", "-0.5px", "-0.5px"],
    },
    typo1: {
      fontSize: "20px",
      lineHeight: "1.5",
    },
    typo2: {
      fontSize: "14px",
      lineHeight: "1.7",
    },
    typo3: {
      fontSize: ["16px", "20px", "20px"],
      lineHeight: "1.5",
    },
    typo4: {
      fontSize: "16px",
      lineHeight: "1.5",
    },
    typo5: {
      fontSize: ["16px", "16px", "20px"],
      lineHeight: "1.5",
    },
    typo6: {
      fontSize: "14px",
      lineHeight: "1.1",
    },
    typo7: {
      fontSize: "20px",
      lineHeight: "1.5",
      fontFamily: "Publico",
      letterSpacing: "-0.33px",
    },
    typo8: {
      fontSize: ["12px", "14px", "14px"],
      lineHeight: "1",
    },
  },
  fontSize: {
    fontSize12: "12px",
    fontSize14: "14px",
    fontSize15: "15px",
    fontSize18: "18px",
  },
  fontWeight: {
    thin: 100,
    extraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
  lineHeight: {
    big: 1.5,
    medium: 1.25,
    small: 1.15,
    normal: 1,
  },
  viewportSidebar: {
    mobile: "60px",
    tablet: "75px",
    desktop: "82px",
  },
  btn: {
    width: "168px",
    heightSmall: "28px",
    heightMedium: "49px",
    heightBig: "60px",
    primaryBgHover: "#e72934",
    secondaryBgHover: "#e1ecF2",
  },
  opacities: {
    opacity20: 0.2,
    opacity60: 0.6,
  },
  gutter: {
    gutter8: "8px",
    gutter16: "16px",
    gutter12: "12px",
    gutter24: "24px",
    gutter32: "32px",
    gutter36: "36px",
    gutter40: "40px",
    gutter48: "48px",
    gutter52: "52px",
    gutter64: "64px",
  },
  form: {
    fieldHeight: "54px",
    checkboxSize: "20px",
    checkboxMarkWidth: "6px",
    checkboxMarkHeight: "11px",
    radioMarkSize: "8px",
  },
  headerHeight: {
    mobile: "55px",
    desktop: "109px",
  },
  divider: {
    normal: "1127px",
    large: "1232px",
  },
  zIndex: {
    index10: "10",
  },
}

export type Theme = typeof theme

export type WithTheme<T = {}> = { theme: Theme } & T
