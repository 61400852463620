import styled, { css } from "styled-components"
import { Link } from "gatsby"

import { customMedia, media } from "./../../common/MediaQueries"
import { transition } from "./../../common/mixins"

const LinkItemSpanStyled = styled.span`
  ${transition()};
  margin: 0;
  min-width: 0;
  font-size: inherit;
  z-index: 1;
  position: relative;
  background-image: linear-gradient(#f5ff00, #f5ff00),
    linear-gradient(#000, #000);
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  cursor: pointer;

  font-size: 16px;
  font-weight: 300;
  line-height: 1.3;
  word-break: break-word;
  padding: 2px 0 6px;

  @media ${customMedia.maxPhoneSmall} {
    font-size: 15px;
  }

  @media ${media.phone} {
    font-size: 20px;
  }

  @media ${media.tablet} {
    font-size: 18px;
  }
`

const LinkItemStyled = styled(Link)`
  margin: 0;
  min-width: 0;
  appearance: none;
  display: inline;
  text-align: center;
  line-height: inherit;
  text-decoration: none;
  font-size: inherit;
  border: 0;
  border-radius: 4px;
  position: relative;
  background-color: transparent;
  color: currentColor;
  border: 0;
  border-radius: 0;
  padding: 0;
  outline: none;
  text-align: left;

  &:hover {
    ${LinkItemSpanStyled} {
      background-size: 0 3px, 100% 3px;
    }
  }

  ${({ isReversed }) =>
    isReversed &&
    css`
      ${LinkItemSpanStyled} {
        background-image: linear-gradient(#f5ff00, #f5ff00),
          linear-gradient(#fff, #fff);
      }
    `}

  ${({ isAlt }) =>
    isAlt &&
    css`
      ${LinkItemSpanStyled} {
        background-image: linear-gradient(#fff, #fff),
          linear-gradient(#f5ff00, #f5ff00);
      }
    `}

  ${({ isBig }) =>
    isBig &&
    css`
      @media ${media.phone} {
        font-weight: ${({ theme }) => theme.fontWeight.regular};
      }
      @media ${media.tablet} {
        ${LinkItemSpanStyled} {
          font-size: 30px;
        }
      }
    `}
`

export { LinkItemStyled, LinkItemSpanStyled }
