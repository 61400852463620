import { ISocialLinks } from "./../interfaces/ISocialLinks"

export const socialLinks: ISocialLinks[] = [
  {
    name: "Linkedin",
    href: "https://www.linkedin.com/company/luminous-design",
    target: "_blank",
  },
  {
    name: "Twitter",
    href: "https://twitter.com/luminouslondon",
    target: "_blank",
  },
  {
    name: "Instagram",
    href: "https://www.instagram.com/luminouslondon/",
    target: "_blank",
  },
]
