import React, { useMemo } from "react"

import {
  FooterBarStyled,
  FooterBarHomeStyled,
  FooterBarLinksStyled,
  FooterBarLinksItemStyled,
  FooterBarCopyrightStyled,
  FooterLinkStyled,
} from "./styles"

const FooterBar = () => {
  const year = useMemo(() => new Date().getFullYear(), [])
  return (
    <FooterBarStyled>
      <FooterBarHomeStyled>
        <FooterLinkStyled
          href="https://www.luminous.co.uk/"
          target="_blank"
          rel="noopener"
        >
          luminous.co.uk
        </FooterLinkStyled>
      </FooterBarHomeStyled>
      <FooterBarLinksStyled>
        <FooterBarLinksItemStyled>
          <FooterLinkStyled
            href="https://www.luminous.co.uk/privacy-policy-2/"
            target="_blank"
            rel="noopener"
          >
            Privacy policy
          </FooterLinkStyled>
        </FooterBarLinksItemStyled>
        <FooterBarLinksItemStyled>
          <FooterLinkStyled
            href="https://www.luminous.co.uk/cookie-policy/"
            target="_blank"
            rel="noopener"
          >
            Cookie policy
          </FooterLinkStyled>
        </FooterBarLinksItemStyled>
      </FooterBarLinksStyled>
      <FooterBarCopyrightStyled>
        <span>© {year} Luminous.</span> <span>All rights reserved.</span>
      </FooterBarCopyrightStyled>
    </FooterBarStyled>
  )
}
export default FooterBar
