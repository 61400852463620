import React from "react"

import { HubSpotStyled } from "./styles"

interface IProps {
  id: string
  children?: React.ReactNode
  isDownload?: boolean
}

const HubSpot: React.FC<IProps> = ({ children, id, isDownload }) => (
  <HubSpotStyled {...{ id, isDownload }}>{children}</HubSpotStyled>
)

export default HubSpot
