import styled from "styled-components"
import { Link } from "gatsby"

import { transition } from "./../../common/mixins"

const BorderLinkSpanStyled = styled.span`
  ${transition()};
  margin: 0;
  min-width: 0;
  font-size: inherit;
  font-family: ${({ theme }) => theme.fontFamilies.publico};
  z-index: 1;
  position: relative;
  background-image: linear-gradient(#f5ff00, #f5ff00),
    linear-gradient(#000, #000);
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  padding-bottom: 2px;
  cursor: pointer;
`

const BorderLinkStyled = styled(Link)`
  margin: 0;
  min-width: 0;
  appearance: none;
  display: inline;
  text-align: center;
  line-height: inherit;
  text-decoration: none;
  font-size: inherit;
  border: 0;
  border-radius: 4px;
  position: relative;
  background-color: transparent;
  color: currentColor;
  border: 0;
  border-radius: 0;
  padding: 0;
  outline: none;
  text-align: left;

  &:hover {
    ${BorderLinkSpanStyled} {
      background-size: 0 3px, 100% 3px;
    }
  }
`

export { BorderLinkStyled, BorderLinkSpanStyled }
