import { customMedia, media } from "./MediaQueries"
import { numbersColor } from "./../constants/numbersColor"

export const transition = (delay = "0", duration = "0.45") => {
  return `
    transition: ${duration}s cubic-bezier(0, 0, 0.05, 0.97) ${delay}s;
  `
}

export const scrollBar = `
::-webkit-scrollbar-track {
  background-color: #004650;
}
::-webkit-scrollbar {
  width: 4px;
  background-color: #004650;
  @media ${media.phone} {
    width: 10px;
  }
}
::-webkit-scrollbar-thumb {
  background-color: #F5FF00;
}
`

export const linkItem = `
  border-bottom-color: #FFF;
  padding: 0;

  line-height: 1.3;
  font-weight: 300;
  display: inline-block;
  border-width: 2px 0;
  border-style: solid;
  border-top-color: transparent;
  position: relative;
  word-break: break-word;
  padding: 2px 0 4px;
  border-radius: 0;
  cursor: pointer;

  font-size: 16px;

  @media ${customMedia.maxPhoneSmall} {
    font-size: 15px;
  }

  @media ${media.phone} {
    font-size: 20px;
  }

  @media ${media.tablet} {
    font-size: 18px;
  }

  &:hover {
    &:after {
      width: 100%;
    }
    &:before {
      width: 100%;
    }
  }

  &::after {
    content: "";
    position: absolute;
    height: 2px;
    bottom: -2px;
    left: 0;
    width: 0;
    transition: 250ms ease-in-out;
    display: flex;
    background-color: #F5FF00;
  }
`

export const numbersAnimation = (
  i: number,
  amount: number,
  prop = "animation-duration"
) => {
  return `
  &:nth-child(${i + 1}) {
    figure {
      ${prop}: ${`calc(${amount} * ${i + 1.3}s)`};
    }
  }
  `
}

export const getNumbersAnimation = (val: number) => {
  let str = ""
  for (let index = 0; index < 5; index += 1) {
    str += numbersAnimation(index, val)
  }
  return str
}

export const gridAreaNthChild = (i: number) => {
  return `
  &:nth-child(${i + 1}) {
    grid-area: ${i + 1};
  }
  `
}

export const getAreaNthChild = (val: number, count: number) => {
  let str = ""
  for (let index = 0; index < count; index += 1) {
    str += gridAreaNthChild(index)
  }
  return str
}

export const generateNthChildColor = (
  i: number,
  parent: React.ReactNode,
  child: React.ReactNode,
  color: string
) => {
  return `
  &:nth-child(n + ${i}) { 
    ${parent} {
      &:hover {
        ${child} {
          background-color: ${color};
        }
      }
    }
  }
  `
}

export const getGenerateNthChildColor = (
  firstChild: number,
  count: number,
  parent: React.ReactNode,
  child: React.ReactNode
) => {
  let str = ""
  for (let index = firstChild; index < count; index += 1) {
    let randomColor = Math.floor(Math.random() * 5)
    str += generateNthChildColor(
      index,
      parent,
      child,
      numbersColor[randomColor]
    )
  }
  return str
}
