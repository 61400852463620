import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { ThemeProvider } from "styled-components"
import GlobalStyles from "../common/GlobalStyles"
import theme from "../themes/theme"

import Viewport from "../components/Viewport"
import {
  ViewportSidebarStyled,
  ViewportContentStyled,
} from "../components/Viewport"

import Header from "../components/Header"
import Main from "../components/Main"
import Footer from "../components/Footer"
import CookieBar from "../components/CookieBar"

import { navigationMenu } from "./../mocks/navigationMenu"
import { socialLinks } from "./../mocks/socialLinks"

interface IProps {
  withoutFooter?: boolean
  isDarkBg?: boolean
}

const Layout: React.FC<IProps> = ({ children, withoutFooter, isDarkBg }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Viewport {...{ isDarkBg }}>
        <ViewportSidebarStyled>
          <Header menu={navigationMenu} socials={socialLinks} />
        </ViewportSidebarStyled>
        <ViewportContentStyled>
          <Main>{children}</Main>
          {!withoutFooter && <Footer />}
        </ViewportContentStyled>
      </Viewport>
      <CookieBar
        cookieExpire={150}
        cookieName="brightest-brands-cookie"
        buttonText="I agree!"
        contentText={
          <>
            Luminous uses cookies to improve your online experience. Find out
            more about our{" "}
            <a
              href="https://www.luminous.co.uk/cookie-policy/"
              target="_blank"
              rel="noopener"
            >
              Cookie Policy
            </a>
            .
          </>
        }
      />
    </ThemeProvider>
  )
}

export default Layout
