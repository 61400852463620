import React from "react"
import styled, { css } from "styled-components"

import { media } from "./../common/MediaQueries"

const ViewportContainerStyled = styled.div`
  max-width: 100%;
  margin: 0 auto;
  width: 100%;

  ${({ isDarkBg }) =>
    isDarkBg &&
    css`
      background: ${({ theme }) => theme.colors.black};
    `}
`

const ViewportStyled = styled.div`
  position: relative;
  min-height: 100%;
  width: 100%;
  height: 100%;
  min-height: calc(100 * var(--vh));
  display: flex;
  flex-direction: row;
  overflow: hidden;
  @media ${media.tablet} {
    overflow: auto;
    min-height: 100vh;
  }
`

const ViewportSidebarStyled = styled.div`
  position: fixed;
  top: 0;

  z-index: 999;
  width: ${({ theme }) => theme.viewportSidebar.mobile};
  background-color: ${({ theme }) => theme.colors.black};

  height: calc(100 * var(--vh));

  @media ${media.phone} {
    min-height: calc(100 * var(--vh));
    height: 100%;
    width: ${({ theme }) => theme.viewportSidebar.tablet};
  }
  @media ${media.tablet} {
    width: ${({ theme }) => theme.viewportSidebar.desktop};
    min-height: 100vh;
  }
`

const ViewportContentStyled = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: ${({ theme }) => theme.viewportSidebar.mobile};
  bottom: 0;
  overflow-y: auto;
  flex: 1;
  width: calc(100% - 60px);
  height: 100%;
  min-height: calc(100 * var(--vh));
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    overflow-x: hidden;
  }
  @media ${media.phone} {
    width: calc(100% - 75px);
    left: ${({ theme }) => theme.viewportSidebar.tablet};
  }
  @media ${media.tablet} {
    width: calc(100% - 82px);
    left: ${({ theme }) => theme.viewportSidebar.desktop};
    height: 100%;
    min-height: 100vh;
  }
`

interface IProps {
  children: React.ReactNode
  isDarkBg?: boolean
}

const Viewport: React.FC<IProps> = ({ children, isDarkBg }) => (
  <ViewportContainerStyled {...{ isDarkBg }}>
    <ViewportStyled>{children}</ViewportStyled>
  </ViewportContainerStyled>
)

export default Viewport
export { ViewportSidebarStyled, ViewportContentStyled }
