import { IUrlItem } from "./../interfaces/IUrlItem"

export const navigationMenu: IUrlItem[] = [
  {
    title: "Home",
    url: "/",
    target: false,
  },
  {
    title: "Introduction",
    url: "/introduction/",
    target: false,
  },
  {
    title: "Methodology",
    url: "/methodology/",
    target: false,
  },
  {
    title: "The Top 50",
    url: "/top-50/",
    target: false,
  },
  {
    title: "Sectors",
    url: "/download-report/",
    target: false,
  },
]
