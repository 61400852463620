import React from "react"
import { LinkItemStyled, LinkItemSpanStyled } from "./styles"

interface IProps {
  as?: string
  url?: string
  text: string
  target?: boolean
  isAlt?: boolean
  isBig?: boolean
  isReversed?: boolean
  rel?: string
  href?: string
}

const LinkItem: React.FC<IProps> = ({
  as,
  url,
  href,
  text,
  target,
  isAlt,
  isBig,
  isReversed,
  rel,
}) => (
  <LinkItemStyled
    {...{ as, isAlt, isBig, isReversed, target, rel, href }}
    to={url}
  >
    <LinkItemSpanStyled>{text}</LinkItemSpanStyled>
  </LinkItemStyled>
)
export default LinkItem
