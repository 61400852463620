import styled from "styled-components"

import { media } from "./../common/MediaQueries"

const InnerContainer = styled.div`
  width: 100%;
  padding-left: 16px;
  padding-top: 56px;
  padding-right: 10px;
  @media ${media.phone} {
    padding-left: 78px;
    padding-top: 74px;
    padding-right: 20px;
  }
  @media ${media.tablet} {
    padding-left: 136px;
  }
`

export { InnerContainer }
