import React from "react"
import CookieConsent from "react-cookie-consent"

import { CookieBarStyled, CookieBarInnerStyled } from "./styles"

interface IProps {
  cookieName: string
  cookieExpire: number
  contentText: React.ReactNode
  buttonText: string
}

const CookieBar: React.FC<IProps> = ({
  cookieName,
  cookieExpire,
  contentText,
  buttonText,
}) => {
  const cookieRef = React.createRef()

  return (
    <CookieBarStyled ref={cookieRef}>
      <CookieBarInnerStyled>
        <CookieConsent
          buttonText={buttonText}
          buttonClasses="cookieConsent__button"
          contentClasses="cookiesConsent__text"
          cookieName={cookieName}
          expires={cookieExpire}
          disableStyles
          hideOnAccept={false}
          onAccept={() =>
            (cookieRef.current.style.transform = "translateY(100%)")
          }
        >
          {contentText}
        </CookieConsent>
      </CookieBarInnerStyled>
    </CookieBarStyled>
  )
}

export default CookieBar
