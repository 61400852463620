import styled from "styled-components"

import { media } from "./../../common/MediaQueries"
import { transition } from "./../../common/mixins"

const CookieBarInnerStyled = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  padding: 0 25px;
  margin: 0 auto;
`

const CookieBarStyled = styled.div`
  ${transition()};
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.black};
  z-index: 1000;

  .CookieConsent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
    justify-content: center;
    padding: 1rem 0;
    color: ${({ theme }) => theme.colors.white};
    width: 100%;

    @media ${media.phone} {
      flex-direction: row;
      align-items: center;
    }

    > div {
      @media ${media.phone} {
        align-self: center;
      }
    }

    .cookiesConsent__text {
      font-size: 15px;
      font-weight: ${({ theme }) => theme.fontWeight.regular};
      margin-bottom: 10px;
      color: ${({ theme }) => theme.colors.white};

      @media ${media.phone} {
        margin-bottom: 0;
        padding-right: 24px;
      }

      a {
        color: ${({ theme }) => theme.colors.brand};
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }

    .cookieConsent__button {
      ${transition()};
      display: inline-flex;
      align-items: center;
      flex-shrink: 0;
      color: ${({ theme }) => theme.colors.black};
      border-radius: 30px;
      height: 30px;
      position: relative;
      background-color: ${({ theme }) => theme.colors.brand};
      padding: 0px 10px;
      overflow: hidden;
      text-transform: uppercase;
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      font-size: 12px;
      padding: 5px 16px;
      text-align: center;
      cursor: pointer;

      &:hover {
        background-color: ${({ theme }) => theme.colors.helio};
        color: ${({ theme }) => theme.colors.main};
      }

      @media ${media.phone} {
        height: 40px;
        width: 86px;
      }
    }
  }
`

export { CookieBarStyled, CookieBarInnerStyled }
