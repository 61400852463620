import React, { useState, useEffect } from "react"

import {
  HeaderStyled,
  HeaderTopStyled,
  HeaderNavigationWrapperStyled,
  HeaderNavigationStyled,
  HeaderNavigationMenuStyled,
  HeaderLogoStyled,
  HeaderNavigationWrapStyled,
  HeaderNavigationBlockStyled,
  HeaderNavigationBlockItemStyled,
  HeaderNavigationFooterStyled,
  HeaderNavigationFormStyled,
  HeaderNavigationNewsletterStyled,
  HeaderNavigationBlockHeadStyled,
  HeaderInnerContainerStyled,
} from "./styles"
import MainNav from "./../MainNav"
import SocialMedia from "./../SocialMedia"
import Burger from "./../Burger"
import FooterBar from "./../FooterBar"
import Logo from "./../Logo"
import HubSpot from "./../HubSpot"
import InTouchBlock from "./../InTouchBlock"
import {
  PORTAL_ID,
  DOWNLOAD_FORM_ID,
  NEWSLETTER_FORM_ID,
} from "./../../constants/hubspot"

interface IProps {
  menu: React.ReactNode
  socials: React.ReactNode
}

const Header: React.FC<IProps> = ({ menu, socials }) => {
  const initializeHubspotForm = () => {
    if (window.hbspt) {
      window.hbspt.forms.create({
        portalId: PORTAL_ID,
        formId: DOWNLOAD_FORM_ID,
        target: "#download",
      })
      // window.hbspt.forms.create({
      //   portalId: PORTAL_ID,
      //   formId: NEWSLETTER_FORM_ID,
      //   target: "#newsletter",
      // })
    } else {
      setTimeout(initializeHubspotForm, 500)
    }
  }

  useEffect(() => {
    initializeHubspotForm()
  }, [])

  const [menuOpen, setMenuOpen] = useState(false)
  const toggleMenuHandler = () => {
    setMenuOpen(!menuOpen)
  }

  if (typeof window !== "undefined") {
    // add style for body when mobile menu is expanded
    const body = document.querySelector("body")
    useEffect(() => {
      if (menuOpen) {
        body.style.overflow = "hidden"
      } else {
        body.style.overflow = ""
      }
      window.addEventListener("resize", () => {
        document
          .querySelector(":root")
          .style.setProperty("--vh", window.innerHeight / 100 + "px")
      })
    }, [menuOpen])

    document
      .querySelector(":root")
      .style.setProperty("--vh", window.innerHeight / 100 + "px")
  }

  return (
    <HeaderStyled>
      <HeaderTopStyled>
        <HeaderNavigationWrapperStyled>
          <HeaderNavigationStyled isOpened={menuOpen}>
            <HeaderInnerContainerStyled>
              <HeaderNavigationWrapStyled>
                <HeaderNavigationBlockStyled>
                  <HeaderNavigationBlockItemStyled>
                    <HeaderNavigationMenuStyled>
                      <MainNav items={menu} />
                    </HeaderNavigationMenuStyled>
                  </HeaderNavigationBlockItemStyled>
                  <HeaderNavigationBlockItemStyled>
                    <HeaderNavigationBlockItemStyled
                      mb={["46px", "40px", "86px"]}
                      maxWidth={["340px", "350px", "480px"]}
                    >
                      <InTouchBlock
                        isReversed
                        heading={<>How bright is your brand? Get in touch</>}
                      />
                    </HeaderNavigationBlockItemStyled>
                    <HeaderNavigationBlockItemStyled>
                      <HeaderNavigationFormStyled>
                        <HeaderNavigationBlockHeadStyled>
                          For further insights and sector deep dives, please
                          complete the form to download the full report
                        </HeaderNavigationBlockHeadStyled>
                        <HubSpot id="download" isDownload></HubSpot>
                      </HeaderNavigationFormStyled>
                    </HeaderNavigationBlockItemStyled>
                  </HeaderNavigationBlockItemStyled>
                </HeaderNavigationBlockStyled>
                <HeaderNavigationBlockStyled isReversedMobile>
                  <HeaderNavigationBlockItemStyled>
                    <HeaderNavigationBlockHeadStyled>
                      Follow us
                    </HeaderNavigationBlockHeadStyled>
                    <SocialMedia items={socials} />
                  </HeaderNavigationBlockItemStyled>
                  {/* <HeaderNavigationBlockItemStyled>
                    <HeaderNavigationNewsletterStyled>
                      <HeaderNavigationBlockHeadStyled>
                        Sign up for Luminous updates and events
                      </HeaderNavigationBlockHeadStyled>
                      <HubSpot id="newsletter"></HubSpot>
                    </HeaderNavigationNewsletterStyled>
                  </HeaderNavigationBlockItemStyled> */}
                </HeaderNavigationBlockStyled>
                <HeaderNavigationFooterStyled>
                  <FooterBar />
                </HeaderNavigationFooterStyled>
              </HeaderNavigationWrapStyled>
            </HeaderInnerContainerStyled>
          </HeaderNavigationStyled>
          <Burger isOpened={menuOpen} clickHandler={toggleMenuHandler} />
        </HeaderNavigationWrapperStyled>
      </HeaderTopStyled>
      <HeaderLogoStyled>
        <Logo href="/" fontSize="12px" height="27px" />
      </HeaderLogoStyled>
    </HeaderStyled>
  )
}
export default Header
