import styled, { css } from "styled-components"

import { media } from "./../../common/MediaQueries"
import { transition, linkItem } from "./../../common/mixins"

const HubSpotStyled = styled.div`
  width: 100%;

  .hs-form {
    position: relative;
    z-index: 3;
  }

  .hs-form-field {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .hs-form-field > label {
    display: none;
  }

  .input {
    background-color: ${({ theme }) => theme.colors.black};
  }

  .hs-input {
    font-family: ${({ theme }) => theme.fontFamilies.arsMasquette};
    width: 100%;
    max-width: 540px;
    height: 32px;
    font-size: 16px;
    font-weight: ${({ theme }) => theme.fontWeight.light};
    line-height: 1px;
    color: ${({ theme }) => theme.colors.white};
    background-color: rgba(246, 246, 246, 0.2);
    padding: 2px 12px;
    border: 0;
    border-radius: 0;
    outline: 0;

    @media ${media.phone} {
      font-size: 20px;
    }
  }

  .hs-submit {
    margin-top: 20px;
  }

  .hs-button {
    ${transition()};
    ${linkItem};
    font-family: ${({ theme }) => theme.fontFamilies.arsMasquette};
    background: transparent;
    color: ${({ theme }) => theme.colors.white};
    border-bottom-color: ${({ theme }) => theme.colors.white};
    outline: 0;

    &::after {
      background-color: ${({ theme }) => theme.colors.brand};
    }

    &:hover {
      border-bottom-color: ${({ theme }) => theme.colors.brand};
    }
  }

  .hs-error-msg {
    font-size: 10px;
    color: ${({ theme }) => theme.colors.red};
  }

  .submitted-message {
    color: ${({ theme }) => theme.colors.brand};
  }

  .hs-error-msgs .hs-main-font-element {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.red};
  }
  .hs-fieldtype-booleancheckbox {
    margin-top: 16px;
  }
  .hs-form-booleancheckbox > label {
    display: flex;
  }
  .hs-form-booleancheckbox > label > input {
    position: relative;
    top: 1px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    margin: 0 12px 0 0;
  }

  ${({ isDownload }) =>
    isDownload &&
    css`
      .hs-button {
        border-bottom-color: ${({ theme }) => theme.colors.brand};
        &:hover {
          border-bottom-color: ${({ theme }) => theme.colors.white};
        }
      }
    `}
`

export { HubSpotStyled }
