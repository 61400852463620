import React from "react"

import {
  MainNavStyled,
  MainNavListStyled,
  MainNavItemStyled,
  MainNavLinkStyled,
} from "./styles"
import { IUrlItem } from "./../../interfaces/IUrlItem"

export interface IUrlItemList {
  items: IUrlItem[]
}

interface IProps {
  items?: React.ReactNode
}

const MainNav: React.FC<IProps> = ({ items }) => {
  const activeStyle = { color: "#F5FF00" }

  return (
    <MainNavStyled>
      {items && (
        <MainNavListStyled>
          {items.map(({ title, url }) => (
            <MainNavItemStyled key={`${url}${title}`}>
              <MainNavLinkStyled to={url} activeStyle={activeStyle}>
                {title}
              </MainNavLinkStyled>
            </MainNavItemStyled>
          ))}
        </MainNavListStyled>
      )}
    </MainNavStyled>
  )
}

export default MainNav
