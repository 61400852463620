import styled from "styled-components"

import { media } from "./../../common/MediaQueries"
import { InnerContainer } from "./../InnerContainer"

const FooterWrapperStyled = styled.footer`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
`

const FooterInnerStyled = styled(InnerContainer)`
  padding-right: 16px;
  @media ${media.tablet} {
    padding-right: 50px;
  }
  @media ${media.desktopXl} {
    padding-right: 100px;
  }
`

const FooterStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 0 32px;
  @media ${media.phone} {
    padding: 100px 0 20px;
  }
  @media ${media.tablet} {
    padding: 90px 0 38px;
  }
`

const FooterTopStyled = styled.div`
  margin-bottom: 62px;
  @media ${media.phone} {
    margin-bottom: 156px;
  }
  @media ${media.tablet} {
    margin-bottom: 196px;
  }
`

const FooterBottomStyled = styled.div``

const FooterBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  @media ${media.phone} {
    flex-direction: row;
  }
`

const FooterBlockInTouchStyled = styled.div`
  width: 100%;
  max-width: 410px;
  margin-bottom: 40px;
  @media ${media.phone} {
    width: calc(100% - 200px);
    max-width: 530px;
    padding-right: 32px;
    margin-bottom: 0;
  }
  @media ${media.tablet} {
    width: calc(100% - 294px);
  }
`

const FooterBlockLinksStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${media.phone} {
    width: 200px;
  }
  @media ${media.tablet} {
    width: 294px;
  }
`

const FooterBlockLinksItemStyled = styled.div`
  &:not(:last-child) {
    margin-bottom: 34px;
  }
  @media ${media.phone} {
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
  @media ${media.tablet} {
    &:not(:last-child) {
      margin-bottom: 70px;
    }
  }
`

export {
  FooterWrapperStyled,
  FooterInnerStyled,
  FooterStyled,
  FooterTopStyled,
  FooterBottomStyled,
  FooterBlockStyled,
  FooterBlockInTouchStyled,
  FooterBlockLinksStyled,
  FooterBlockLinksItemStyled,
}
