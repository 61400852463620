import styled from "styled-components"
import Link from "gatsby-link"
import { fontSize, height } from "styled-system"

import { transition } from "./../../common/mixins"

const LogoLinkStyled = styled(Link)`
  display: flex;
  transform: rotate(90deg);
`

const LogoIconStyled = styled.span`
  display: inline-flex;
  flex-shrink: 0;

  svg {
    ${fontSize}
    ${height}
    width: 159px;
    fill: currentColor;
  }
`

export { LogoLinkStyled, LogoIconStyled }
