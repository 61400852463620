import React from "react"

import { LogoLinkStyled, LogoIconStyled } from "./styles"

interface IProps {
  href: string
  fontSize: string
  height: string
}

const Logo: React.FC<IProps> = ({ href, fontSize, height }) => {
  return (
    <LogoLinkStyled to={href} aria-label="Homepage">
      <LogoIconStyled fontSize={fontSize} height={height}>
        <svg viewBox="0 0 184 30" height="1em" width="6.13em">
          <path
            d="M183.9,24.2c-0.3-0.5-0.9-0.3-1.3,0c-0.1,0.1-0.3,0.2-0.4,0.3c-1.1,0.7-2.4,0.8-3.7,1.1c-0.4,0.1-0.7,0.2-1.1,0.3
		c-0.2-0.4-0.4-0.8-0.7-1.2c-0.1-0.2-0.3-0.5-0.5-0.7l-0.6-0.8l-0.1-0.1l-0.3-0.5l0,0c0,0-0.1-0.1-0.1-0.1c-0.5-0.7-1.4-1.7-1.5-2.7
		c0-0.4,0.4-0.4,0.4-0.5c0-0.1,0.3-0.1,0.4-0.1c0.1,0,0.3,0,0.4-0.1c0.3,0,0.5,0,0.9,0s0.3,0,0.6,0c0.5,0,1-0.5,0.9-0.9
		c-0.1-0.4-0.5-0.4-0.8-0.5c-0.2,0-0.4,0-0.6,0c-0.7,0-1.3,0.1-2,0.2c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.3
		c-0.6,0.4-1.2,0.8-1.8,1.3l-0.5,0.4c-0.2,0.1-0.3,0.2-0.5,0.4c-1,0.6-2.1,1.3-3.1,1.9c-0.9,0.5-1.9,0.9-2.9,1.2
		c-0.6,0.2-1.2,0.3-1.8,0.3c-0.3,0-0.7,0-1-0.1c-0.4-0.1-0.7-0.4-0.8-0.7c-0.1-0.3-0.2-0.6-0.3-1c0-0.6,0-1.2,0.2-1.8
		c0-0.3,0-0.5-0.3-0.6c0,0-0.1,0-0.1,0c0,0-0.5-0.2-1.2,0.7c-1.6,2.2-4.3,4.9-7.3,4.3c-0.3,0-0.5-0.2-0.7-0.4
		c-0.3-0.6,0.1-1.6,0.3-2.1c0.3-0.7,0.7-1.4,1.1-2c0.2-0.2,0.3-0.4,0.4-0.7c0.1-0.2,0.1-0.4,0-0.6c-0.2-0.4-0.7-0.6-1.2-0.4
		c-0.2,0.1-0.3,0.2-0.5,0.2c-2,1.1-4.2,1.9-6.5,2.4c-2.2,0.4-4.3,0.4-6.5,0.4h-0.3c-1.4-0.1-1.6,0.1-2,0.9c-0.5,0.8-1.1,1.5-1.9,2.1
		c-0.4,0.2-1,0.7-1.5,0.5c-0.2-0.1-0.3-0.4-0.3-0.6c-0.1-0.3-0.1-0.7,0-1c0-0.5,0.1-0.9,0.3-1.4l2.5-1.6c0.3-0.2,0.6-0.4,0.8-0.7
		c0.1-0.2,0.1-0.4,0.1-0.5c0-0.1-0.1-0.3-0.2-0.4c-0.4-0.5-1-0.3-1.5-0.1c-0.5,0.2-1,0.4-1.4,0.7c-0.4,0.3-0.7,0.6-0.9,0.9
		c-0.2,0.2-0.3,0.5-0.4,0.8l-0.8,0.6c-1.3,0.8-2.7,1.5-4.1,2.1c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0.1-0.6,0.2-0.9,0.2
		c-0.6,0.1-1.2,0.2-1.9,0.3c-0.6,0-1.2,0-1.8-0.1c-0.6-0.1-1.2-0.3-1.7-0.6c-0.6-0.3-1.1-0.7-1.6-1.1c-0.7-0.7-1.3-1.4-1.9-2.2
		c-0.2-0.3-0.4-0.6-0.6-0.9c-0.1-0.2-0.3-0.5-0.6-0.6c-0.2-0.1-0.4-0.2-0.6-0.2c-0.6,0.2-1.3,0.6-1.9,1c-0.6,0.4-1.3,0.8-1.9,1.2
		c-0.4,0.2-0.7,0.5-1.1,0.7c-1.2,0.8-2.5,1.6-3.8,2.3c-1.1,0.6-2.1,1.1-3.3,1.1c0.2-0.6,0.5-1.2,0.9-1.8l0.8-1.3
		c0.2-0.4,0.5-0.8,0.7-1.2c0.3-0.5,1.1-1.3,0.8-1.9c-0.2-0.4-0.6-0.5-1-0.4c-0.2,0.1-0.5,0.1-0.7,0.3c-1.3,0.6-2.6,1.3-3.9,1.8
		c-0.4,0.2-0.8,0.3-1.2,0.5c-0.6,0.2-1.2,0.5-1.8,0.7c-0.6,0.2-1.2,0.4-1.8,0.7c-0.6,0.2-1.2,0.4-1.8,0.6l-0.2,0.1
		c-1.7,0.6-3.5,1-5.2,1.4c-1.6,0.3-3.3,0.6-4.9,0.4c-0.5,0-0.9-0.2-1.3-0.4c-0.3-0.2-0.6-0.4-0.7-0.7C80,23.3,80,23,80,22.7
		c0-0.3,0-0.5,0.1-0.8c0.2-0.8,0.5-1.5,0.9-2.2c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0-0.3,0-0.4
		c-0.1-0.4-0.5-0.6-0.8-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.3,0.2-0.6,0.4-0.9,0.6c-0.3,0.2-0.6,0.4-0.9,0.6l-0.5,0.3
		c-0.6,0.4-1.3,0.8-2,1.2c-0.7,0.4-1.3,0.7-2,1.1c-0.8,0.4-1.6,0.8-2.4,1.2c-0.2,0.1-0.4,0.2-0.7,0.3c-0.6,0.3-1.3,0.5-2,0.6
		c-0.4,0-0.7,0-1.1-0.1c-0.6-0.1-1.1-0.5-1.5-0.9c-0.5-0.6-0.9-1.3-1.1-2.1l-0.5-1.3c-0.1-0.3-0.3-0.5-0.6-0.4
		c-0.3,0.1-0.5,0.2-0.8,0.4c-0.2,0.1-0.4,0.3-0.6,0.4c-0.4,0.3-0.9,0.7-1.3,1l-0.7,0.5c-0.3,0.2-0.6,0.4-0.8,0.6
		c-0.9,0.7-1.9,1.2-3,1.7c-0.7,0.3-1.6,0.7-2.4,0.5c-0.4-0.1-0.8-0.4-1-0.8c-0.2-0.2-0.3-0.5-0.4-0.8c-0.2-0.5-0.3-0.9-0.3-1.4
		c-0.1-0.5-0.1-1-0.1-1.5c0.1-2-0.9-1.5-0.9-1.5c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.3,0.2-0.5,0.3c-1.5,1-3,1.9-4.6,2.7
		c-0.4,0.3-0.9,0.5-1.3,0.8c0,0-2.7,1.4-3.2,1.6c0,0,1.7-3.3,2.7-4.9c0.6-0.9-0.5-1.7-1.2-1.1c0,0-2.1,1.6-2.9,2.1
		c-1,0.6-2.1,1.3-3.1,1.9c-0.8,0.4-1.6,0.8-2.4,1.1c-0.8,0.3-1.6,0.4-2.4,0.5c-0.3,0-0.6,0-0.9-0.1c-0.4-0.1-0.7-0.4-0.8-0.7
		c-0.1-0.3-0.2-0.6-0.3-1c0-0.3,0-0.6,0-0.9c0.1-0.3,0.1-0.7,0.2-1c0.1-0.6-0.4-0.6-0.4-0.6c-0.4,0.1-0.7,0.3-1,0.5l-0.2,0.2
		c-1.6,2.2-4.3,5-7.3,4.3c-0.3,0-0.5-0.2-0.6-0.4c-0.3-0.6,0.1-1.6,0.3-2.1c0.3-0.7,0.7-1.4,1.1-2l0.3-0.6c0.3-0.6,0.1-0.9,0.1-0.9
		c-0.4-0.6-1.3-0.2-1.8,0.1l-0.1,0.1C18.4,20,16,21.1,13.4,22c-3,1.2-6,2.1-9.2,2.7c-0.3,0.1-0.6,0.1-0.9,0.1c0,0-0.1-0.1-0.1-0.2
		C3,23.7,3,22.7,3.2,21.8C3.3,20.9,3.4,20,4,19.3c0.6-0.7,1.3-1.3,1.9-2s1.1-1.6,1.6-2.4c0.5-0.9,1-1.9,1.5-2.8
		c1.5-3,2.9-6.2,3.3-9.6c0-0.2,0-0.5,0.1-0.7c0-0.3,0-0.6-0.1-0.9c-0.1-0.3-0.3-0.5-0.5-0.7C11.4,0,11.2,0,11,0
		c-0.1,0-0.3,0.1-0.4,0.2c-0.3,0.2-0.5,0.5-0.7,0.7C8.1,3.3,6.9,6.3,5.8,9c-1.2,3-2.1,6-2.9,9.1c-0.5,0.4-1,0.8-1.5,1.2
		c-0.4,0.3-0.8,0.6-1.1,1c-0.3,0.4-0.3,1.2,0.4,1.1c0.3-0.1,0.7-0.2,0.9-0.4c0.1,0,0.6-0.4,0.7-0.5c-0.1,0.9-0.2,1.8-0.3,2.7
		c-0.1,0.7,0,1.4,0.2,2c0.2,0.3,0.4,0.6,0.7,0.8C3.2,26.2,4,26,4,26C9.4,25,14.7,23.3,19.8,21c-0.2,0.4-0.3,0.9-0.5,1.3
		c-0.1,0.3-0.2,0.6-0.2,1c-0.1,0.7,0.1,1.4,0.6,1.9c0.4,0.5,1.1,0.8,1.8,0.8c0.9,0.1,1.9-0.1,2.7-0.5c1.3-0.6,2.5-1.4,3.5-2.4
		c0.2-0.2,0.5-0.4,0.7-0.7c0.2,1.6,1.5,2.8,3.1,2.8c1.8,0.1,3.6-0.8,5.2-1.6c1.2-0.6,3.7-2,3.7-2l0.6-0.4c-0.2,0.3-0.4,0.7-0.6,1.1
		c0,0-0.3,0.7-0.6,1.3c-0.2,0.4-0.5,1.1-0.4,1.6c0.1,0.4,0.5,0.7,0.9,0.8c0.7,0,1.4-0.3,2-0.6c0.7-0.3,1.5-0.7,2.2-1.1
		c1.6-0.9,3.1-1.8,4.6-2.8l1.9-1.3c0,0.6,0.1,1.3,0.2,1.9c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.3,0.1,0.4
		c0.1,0.3,0.2,0.6,0.3,0.8c0.1,0.3,0.2,0.5,0.4,0.8c0.2,0.2,0.3,0.5,0.6,0.7c0.2,0.2,0.5,0.4,0.8,0.5c0.4,0.2,0.8,0.3,1.2,0.2
		c0.2,0,0.4,0,0.6-0.1c3-0.4,5.8-2.7,8.1-4.7c0.7,2.1,2.3,4.3,4.7,4.3c1.9-0.1,3.8-1.1,5.5-1.9s3.4-1.7,5.1-2.6
		c-0.2,1.1-0.4,2.1,0.2,3.2c0.6,1,1.7,1.5,2.9,1.7c2.4,0.3,4.8-0.2,7.1-0.8c4.5-1.1,8.8-2.9,13.1-4.5c-0.1,0-0.2,0.4-0.3,0.4
		l-0.3,0.5c-0.2,0.3-0.4,0.6-0.5,1c-0.3,0.6-0.6,1.3-0.8,2c-0.1,0.4-0.1,0.7,0.1,1.1c0.5,0.8,1.7,0.7,2.5,0.5
		c1.6-0.4,3.1-1.4,4.4-2.3l1.2-0.8c0.3-0.2,0.7-0.5,1-0.7s4-2.4,4.1-2.3c0.1,0.3,0.3,0.6,0.4,0.8c1.7,2.9,4.3,4.9,7.7,4.9
		c3.1,0,6.3-1.3,9-2.9c0,0,0,0.4,0,0.4c0,0.1,0,0.2,0,0.4c0,0.3,0,0.5,0.1,0.8c0.1,0.5,0.3,1,0.7,1.4c0.3,0.3,0.7,0.4,1.2,0.4
		c0.6,0,1.1-0.2,1.6-0.6c0.6-0.4,1.2-0.9,1.7-1.4c0.1-0.2,0.3-0.3,0.4-0.5l0.7-0.8c0.1,0,0.1,0,0.2,0h0.2c2.2,0.2,4.5,0,6.7-0.4
		c2-0.5,4.1-1.1,6-1.8c-0.2,0.5-0.4,1.1-0.6,1.7c-0.1,0.3-0.1,0.6-0.2,0.9c-0.1,0.7,0.1,1.4,0.6,1.9c0.4,0.5,1.1,0.8,1.8,0.8
		c0.5,0.1,1,0,1.4-0.1c0.5-0.1,0.9-0.2,1.4-0.4c0.3-0.1,0.5-0.2,0.7-0.4c0.7-0.4,1.4-0.8,2-1.4l1.4-1.3c0.2,1.6,1.5,2.8,3.1,2.8
		c0.7,0,1.4-0.1,2.1-0.3c1.2-0.3,2.3-0.8,3.4-1.4l5.5-3.1c0.3,0.9,0.8,1.7,1.3,2.4c0,0,0.1,0.1,0.1,0.1l0.4,0.6l0.5,0.6
		c0.4,0.6,0.8,1.3,1.2,1.9l0.1,0.1c-0.9,0.2-1.7,0.6-2.4,1.1c-0.8,0.6-1.3,1.7-0.3,2.3c0.6,0.3,1.3,0.4,2,0.2c0.2,0,0.4-0.1,0.6-0.2
		c1.1-0.4,1.9-1.4,1.8-2.6c0.6-0.2,1.3-0.3,1.9-0.4l0.6-0.1c0.6-0.1,1.1-0.3,1.6-0.5c0.5-0.1,0.9-0.3,1.4-0.6
		c0.2-0.2,0.4-0.3,0.6-0.6C184,24.7,184,24.5,183.9,24.2z M5.2,14.5l0.4-1.1c0.1-0.3,0.2-0.6,0.4-0.9c0.2-0.7,0.5-1.3,0.8-2
		C7.4,9,8,7.5,8.7,6.1C9,5.3,9.4,4.6,9.7,3.9c0.4-0.7,0.8-1.4,1.2-2.1c0,0.2,0,0.4-0.1,0.6c0,0.2-0.1,0.4-0.1,0.6
		c-0.1,0.4-0.1,0.7-0.2,1.1c-0.1,0.3-0.2,0.6-0.2,0.9c-0.2,0.8-0.5,1.6-0.8,2.3C9.4,7.7,9.2,8.1,9.1,8.5C8.9,8.8,8.8,9.2,8.6,9.6
		C8,11,7.3,12.4,6.5,13.8C5.8,15,5,16.2,4.1,17.3L5.2,14.5z M176.3,27.7L176.3,27.7c-0.1,0.1-0.1,0.2-0.1,0.2
		c-0.2,0.3-0.6,0.5-0.9,0.6c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.2,0-0.2,0s-0.2,0-0.2,0c0.3-0.2,0.5-0.4,0.8-0.5
		c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.4-0.2,0.6-0.2C176.3,27.6,176.3,27.7,176.3,27.7z"
          ></path>
          <path
            d="M82.3,12.5c0.2,0,0.5,0,0.8,0c0.4,0,0.9-0.1,1.3-0.2l0.4,0c0.5-0.1,1.1-0.1,1.6-0.2l0.4,0c0.4,0,0.7-0.1,1.1-0.1h0.3
		c0.5,0,0.9-0.1,1.3-0.1c0.3,0,0.6-0.1,0.9-0.1l0.2-0.1c0.2-0.1,0.4-0.2,0.5-0.3c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.2-0.5
		c0-0.1-0.1-0.1-0.1-0.1c-0.4-0.1-0.8,0.1-1.2,0.2c-0.5,0.1-1,0.1-1.4,0.2c-0.4,0-1,0.1-1.6,0.1c-0.8,0.1-1.6,0.1-2.1,0.1
		c-0.6,0-1,0-1.4,0c-0.2,0-0.4,0-0.7,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.1-0.1-0.2,0-0.3,0c-0.4,0.1-0.5,0.6-0.5,0.9
		c0,0.2,0.1,0.5,0.3,0.6C81.8,12.4,82.1,12.5,82.3,12.5z"
          ></path>
        </svg>
      </LogoIconStyled>
    </LogoLinkStyled>
  )
}

export default Logo
