import styled from "styled-components"

const SocialMediaStyled = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
`

const SocialMediaLinkStyled = styled.div``

const SocialMediaListStyled = styled.ul`
  display: flex;
  flex-direction: column;
`

const SocialMediaItemStyled = styled.li`
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`

export {
  SocialMediaStyled,
  SocialMediaListStyled,
  SocialMediaItemStyled,
  SocialMediaLinkStyled,
}
