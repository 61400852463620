import React from "react"

import {
  FooterWrapperStyled,
  FooterInnerStyled,
  FooterStyled,
  FooterTopStyled,
  FooterBottomStyled,
  FooterBlockStyled,
  FooterBlockInTouchStyled,
  FooterBlockLinksStyled,
  FooterBlockLinksItemStyled,
} from "./styles"
import FooterBar from "./../FooterBar"
import InTouchBlock from "./../InTouchBlock"
import { Heading2 } from "./../Typography"
import BorderLink from "./../BorderLink"
import theme from "./../../themes/theme"

const Footer = () => (
  <FooterWrapperStyled>
    <FooterInnerStyled>
      <FooterStyled>
        <FooterTopStyled>
          <FooterBlockStyled>
            <FooterBlockInTouchStyled>
              <InTouchBlock
                heading={<>How bright is your brand? Get in touch</>}
              />
            </FooterBlockInTouchStyled>
            <FooterBlockLinksStyled>
              <FooterBlockLinksItemStyled>
                <Heading2 fontWeight={theme.fontWeight.black}>
                  For further insights and sector deep dives,{" "}
                  <BorderLink
                    text="download the full report"
                    href="/download-report"
                  />
                </Heading2>
              </FooterBlockLinksItemStyled>
              <FooterBlockLinksItemStyled>
                <Heading2 fontWeight={theme.fontWeight.black}>
                  Learn more about our work at{" "}
                  <BorderLink
                    as="a"
                    text="luminous.co.uk"
                    href="http://luminous.co.uk"
                    rel="noopener"
                    target="_blank"
                  />
                </Heading2>
              </FooterBlockLinksItemStyled>
            </FooterBlockLinksStyled>
          </FooterBlockStyled>
        </FooterTopStyled>
        <FooterBottomStyled>
          <FooterBar />
        </FooterBottomStyled>
      </FooterStyled>
    </FooterInnerStyled>
  </FooterWrapperStyled>
)

export default Footer
