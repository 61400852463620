import styled from "styled-components"

import { customMedia, media } from "./../../common/MediaQueries"

const FooterBarStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  font-size: 16px;
  line-height: 1.3;
  font-weight: ${({ theme }) => theme.fontWeight.extraLight};
  color: currentColor;

  @media ${media.phone} {
    flex-direction: row;
    font-size: 18px;
  }
`

const FooterBarHomeStyled = styled.div`
  margin-bottom: 10px;
  @media ${media.phone} {
    margin-right: 26px;
  }
`

const FooterBarLinksStyled = styled.ul`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  @media ${media.phone} {
    margin-right: 26px;
  }
`

const FooterBarLinksItemStyled = styled.li`
  &:not(:last-child) {
    padding-right: 4px;
    &:after {
      content: "|";
      padding-left: 4px;
    }
  }
`

const FooterBarCopyrightStyled = styled.div`
  width: 100%;
  margin-bottom: 10px;

  @media ${customMedia.maxPhone} {
    line-height: 1.5;
    span {
      display: block;
    }
  }

  @media ${media.tablet} {
    width: auto;
  }
`

const FooterLinkStyled = styled.a`
  &:hover {
    text-decoration: underline;
  }
`

export {
  FooterBarStyled,
  FooterBarHomeStyled,
  FooterBarLinksStyled,
  FooterBarLinksItemStyled,
  FooterBarCopyrightStyled,
  FooterLinkStyled,
}
